import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/malleyes/camerainfo/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/malleyes/camerainfo/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/malleyes/camerainfo/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/malleyes/camerainfo/submit',
    method: 'post',
    data: row
  })
}

export const init = () => {
  return request({
    url: '/malleyes/camerainfo/init',
    method: 'get',
    timeout: 100000
  })
}

